@import "~antd/dist/antd.css";
.auth-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.site-layout-content {
  padding: 0.5rem;
  background: #fff;

  height: fit-content;
  margin-top: 0.5rem;
}
.site-layout {
  margin-top: 1rem;
  padding: 0.8rem 0;
}
.center {
  text-align: center;
}
.flex-column {
  display: flex;

  flex-direction: column;
}

.flex-center {
  align-items: center;
  justify-content: center;
}
.flex-space-between {
  justify-content: space-between;
}

.flex-start {
  justify-content: flex-start;
}
.flex-end {
  justify-items: flex-end;
}
.flex-grow-1 {
  flex: 1;
  align-items: stretch;
}
.auth-form {
  padding-top: 1rem;
  max-width: 557px;
  width: 100%;
}

#header {
  position: "fixed";
  z-index: 1;
  width: "100%";
  background: #fff;
}
img.header-image {
  width: 30px;
  border-radius: 30px;
}
.jobs-container {
  margin-top: 0.5rem;
  width: 100%;
  max-width: 100%;
  /* height: 50vh; */
  /* max-height: "100vh"; */
  /* position: fixed; */
  /* overflow-y: "scroll"; */
}
.candidates-container {
  margin-top: 0.5rem;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  /* height: 50vh; */
  /* max-height: "100vh"; */
  /* position: fixed; */
  /* overflow-y: "scroll"; */
}

.job-view-container {
  padding: 0.5rem;
}

.pagination {
  bottom: 5px;
  width: 87%;
  /* position: absolute; */
  text-align: center;
  /* display: flex; */
  justify-content: center;
}
@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
    visibility: hidden;
  }
  .mobile-center {
    text-align: center;
  }
  .mobile-header-flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .flex-basis-60 {
    flex-basis: 100%;
    width: 80vw;
  }
  .flex-basis-40 {
    flex-basis: 100%;
    width: 100vw;
  }
  .flex-basis-100 {
    flex-basis: 100%;
    width: 60vw;
  }
  .flex-space-between {
    justify-content: space-between;
  }
  .header-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  /* .header {
    text-align: center;
    display: flex;
    flex-direction: column;
  } */
  .logo-header-main {
    /* float: left; */

    width: 80px;
    /* height: 31px; */
    margin: auto;

    background: rgb(250, 250, 250);
  }
  .ant-layout-header {
    padding: 0 0.5rem;
    height: fit-content;
  }
  .site-layout-content {
    padding: 1rem;
    background: #fff;

    min-height: 90vh;
    height: fit-content;
    margin-top: 1rem;
  }

  .flex {
    display: flex;
  }
}

@media (min-width: 768px) {
  body {
    background-color: rgb(233, 233, 233);
  }
  .card {
    /* border: 0.5px;
    border-style: solid; */
    background-color: white;
    box-shadow: 1px 1px 2px 2px rgba(237, 237, 237, 0.584);
    margin: 5px 1vw;
    padding: 1rem 0.5vw;
  }

  .description-tab {
    font-size: calc(14px + 0.03vw);
  }
  .new-post-editor {
    min-height: 200px;
  }
  .logo-header-main {
    float: left;
    width: 120px;
    /* height: 31px; */
    margin: 16px 24px 16px 0;
    background: rgb(250, 250, 250);
  }
  .site-layout-content {
    /* padding: 1rem; */
    background: #fff;

    min-height: 90vh;
    height: fit-content;
    margin-top: 1rem;
  }
  .site-layout {
    margin-top: 64px;
    padding: 0 50px;
  }
  .layout-footer {
    text-align: center;
    margin: 10px 50px;
    background: #fff;

    bottom: 0;
  }
  .layout-sider {
    background: #fff;
  }

  .site-layout-background {
    background: #fff;
  }

  #dashboardSidebar {
    position: fixed;
    width: 150px;
    background-color: #ddd;
    font-size: 21px;
    margin-top: 10px;
  }

  #dashboardContent {
    position: relative;
    margin-left: 150px;
    padding: 1rem;
  }

  .avatar {
    max-width: 150px;
  }

  .header-image {
    width: 40px;
    border-radius: 50%;
  }

  #header-text {
    text-align: "center";
    margin: "auto";
    width: "100%";
    padding: "10px";
    height: "35px";
    z-index: 5;
    justify-content: center;
    position: "absolute";
  }

  .header-flex {
    display: flex;
    flex-direction: row;
    align-items: center;

    justify-content: space-between;

    /* align-content: space-around; */
    /* position: relative; */
  }
  .header-flex-item {
    flex-basis: 200px;
  }

  .flex {
    display: flex;
    height: 100%;
  }
  .flex-space-between {
    justify-content: space-between;
  }

  .flex-start {
    justify-content: flex-start;
  }
  .flex-end {
    justify-items: flex-end;
  }
  .flex-grow-1 {
    flex: 1;
    align-items: stretch;
  }
  .flex-grow-2 {
    flex: 1;
  }
  .flex-basis-60 {
    flex-basis: 60%;
  }
  .flex-basis-40 {
    flex-basis: 40%;
  }
  .flex-basis-100 {
    flex-basis: 100%;
  }

  .search-bar-margin {
    margin: 1rem;
  }

  .jobs-container {
    margin-top: 0.5rem;
    max-width: 400px;
    height: 80vh;
    max-height: 100vh;
    position: fixed;
    /* overflow-y: "scroll"; */
  }

  .job-view-container {
    padding: 1rem 5rem;
  }

  .pagination {
    bottom: 0px;
    width: 100%;
    /* position: absolute; */
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .site-layout {
    margin-top: 44px;
    padding: 1rem 0;
  }
}

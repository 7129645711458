.drawer {
  right: 0;
  top: 0;
  z-index: 200;
  box-shadow: 1px 0px 7px rgba(240, 240, 240, 0.872);
  background: #fff;
  width: 40%;
  height: 100%;
  position: fixed;
  transform: translateX(100%);
  transition: transform 0.5s ease-in;
  overflow: scroll;
}
.drawer-open {
  transform: translateX(0%);
  transition: transform 0.5s ease-out;
}
.applicantlist {
  width: 0%;
  display: none;
}

.showList {
  width: 100%;
  display: block;
}

.JobsTableContainer {
  width: 100%;
  overflow: scroll;

  display: flex;
}

.JobsTable {
  width: 100%;
  transition: idth 1s ease-in-out;
}

.hideTable {
  width: 10%;
  transition: width 1s ease-in-out;
}

.hide {
  visibility: hidden;

  opacity: 0;
  transition: width 1s ease-out, visibility 1s ease-out, opacity 0.5s ease-out;
}

.show {
  visibility: visible;

  opacity: 1;
  transition: idth 1s ease-in, visibility 1s ease-in, opacity 0.5s ease-in;
}

@keyframes moveTable {
  0% {
    display: none;
  }
  100% {
    display: block;
  }
}

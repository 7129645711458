.homepage {
  /* vertical-align: middle; */
  height: 100vh;

  background-image: url("http://hanyc.org/wp-content/uploads/2017/08/Homepage-banner2-SR.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: left;
  align-items: center;
}
.regular-search .ant-input {
  font-size: 20px;
  line-height: 3rem;
}
.regular-search .ant-input-search-button {
  height: 3rem;
}
.regular-search {
  width: 30%;
  transition: width 0.5s ease-in-out;
}

.onClick {
  width: 50%;
  transition: width 0.5s ease-in-out;
}
.searchBarIcon .anticon .anticon-search {
  font-size: 60px;
  transition: font-size margin-top 0.5s ease-in;
}
.search-container {
  width: 100%;
  padding: 0 8.5rem;
}

.search-container-heading {
  max-width: 53.13rem;
  color: white;
  font-size: 3rem;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.navbar {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.logo {
  width: 200px;
  height: 80px;
  margin-left: 160px;
  margin-top: 30px;
  box-shadow: 0 0 5px 2px #a09f9f;
  background: url("../images/MF4Vfqcg.png") no-repeat;

  background-size: contain;
}
.nav-login {
  color: white;
  font-weight: 700;
  margin-right: 160px;
  margin-top: 30px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .search-container {
    width: 100%;
    padding: 0 2.5rem;
  }
}

@media only screen and (max-width: 992px) {
  .search-container {
    width: 100%;
    padding: 0 2.5rem;
  }
  .logo {
    width: 200px;
    height: 80px;
    margin-left: 160px;
    margin-top: 30px;
    box-shadow: 0 0 5px 2px #282a2d;
    background: url("../images/MF4Vfqcg.png") no-repeat;

    background-size: contain;
  }
  .nav-login {
    color: white;
    font-weight: 700;
    margin-right: 160px;
    margin-top: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .search-container {
    width: 100%;
    padding: 0 2.5rem;
  }
  .logo {
    width: 150px;
    height: 80px;
    margin-left: 1rem;
    margin-top: 2rem;
    box-shadow: 0 0 5px 2px #282a2d;
    background: url("../images/MF4Vfqcg.png") no-repeat;

    background-size: contain;
  }
  .nav-login {
    color: white;
    font-weight: 600;
    margin-right: 1rem;
    margin-top: 2rem;
  }
  .regular-search {
    width: 50%;

    transition: width 1s ease-in;
  }

  .onClick {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .search-container {
    width: 100%;
    padding: 0 0.5rem;
  }
  .logo {
    width: 100px;
    height: 40px;
    margin-left: 1rem;
    margin-top: 2rem;
    box-shadow: 0 0 5px 2px #282a2d;
    background: url("../images/MF4Vfqcg.png") no-repeat;

    background-size: contain;
  }
  .nav-login {
    color: white;
    font-weight: 700;
    margin-right: 1rem;
    margin-top: 2rem;
  }

  .regular-search {
    width: 100%;

    transition: width 1s ease-in;
  }

  .onClick {
    width: 100%;
  }
}
